import React from 'react';

export default function Input(props) {
	let { label, id, required, ...other } = props;

	label = required ? `${label}*` : label;

	return (
		<div className="focus-within:text-primary text-tertiary">
			<label htmlFor={id}>{label}</label>
			<input
				id={id}
				required={required}
				{...other}
				className="block w-full py-1 mt-1 text-white bg-transparent border-b-2 focus:outline-none border-tertiary focus:border-primary"
			/>
		</div>
	);
}
