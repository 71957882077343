import React, { useEffect, useRef, useState } from 'react';

import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Input from '../components/input.jsx';
import Layout from '../components/layout.jsx';
import Button from '../components/button.jsx';
import Section from '../components/section.jsx';

import useLoadingState from '../hooks/use-loading-state.js';

export default function Career(props) {
	let { data } = props;
	let { career } = data;
	let { title, slug, description, requirements, city } = career;
	let formRef = useRef();
	let [executePostCandidate, postingCanidate, pendingCandidate] = useLoadingState(handlePostCandidate);
	let [error, setError] = useState();
	let [success, setSuccess] = useState();

	async function handlePostCandidate() {
		let formData = new FormData(formRef.current);
		formData.append(
			'candidate[name]',
			formData.get('candidate[firstname]') + ' ' + formData.get('candidate[lastname]'),
		);
		formData.append('candidate[referrer]', 'Brainjar Website');

		formData.delete('candidate[firstname]');
		formData.delete('candidate[lastname]');

		let response = await fetch(`https://raccoons.recruitee.com/api/offers/${slug}/candidates`, {
			method: 'POST',
			body: formData,
		});

		if (response.ok) {
			setSuccess(true);
			formRef.current.reset();
		} else {
			setError(true);
		}
	}

	async function handleSubmit(event) {
		event.preventDefault();
		if (postingCanidate) return;

		executePostCandidate();
	}

	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError(false);
			}, 7500);
		}
	}, [error]);

	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setSuccess(false);
			}, 7500);
		}
	}, [success]);

	let renderSuccess;
	if (success) {
		renderSuccess = (
			<div className="block mt-4 -mb-8 text-sm text-primary">
				{"Thanks for reaching out! We'll get in touch with you soon."}
			</div>
		);
	}

	let renderError;
	if (error) {
		renderError = (
			<div className="block mt-4 -mb-8 text-sm text-red-500">Something went wrong! Please try again later.</div>
		);
	}

	return (
		<Layout>
			<Section>
				<div className="grid gap-12 lg:grid-cols-2">
					<div className="lg:max-w-md self-y-center">
						<h1 className="text-4xl">
							We are looking for a <span className="text-primary">{title}</span>
							<br />
							<span className="flex items-center mt-2 text-xl">
								<svg className="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
									/>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
									/>
								</svg>
								{city}
							</span>
						</h1>

						<div className="flex justify-center mt-12 md:justify-start">
							<Button as="link" to="#apply">
								Apply Now
							</Button>
						</div>
					</div>

					<div>
						<StaticImage src="../images/ship.svg" alt="Spaceship" />
					</div>
				</div>
			</Section>

			<Section>
				<article className="prose xl:prose-xl">
					<h3>Job description</h3>
					<div dangerouslySetInnerHTML={{ __html: description }}></div>
					<h3>Requirements</h3>
					<div dangerouslySetInnerHTML={{ __html: requirements }}></div>
				</article>
				<div id="apply" className="max-w-4xl mx-auto">
					<h2 className="pt-12 text-3xl text-center text-white">Ready for a new challenge?</h2>

					{renderSuccess}
					{renderError}
					<form ref={formRef} className="grid w-full gap-8 mx-auto mt-12 lg:grid-cols-2" onSubmit={handleSubmit}>
						<Input type="text" label="First name" id="firstname" name="candidate[firstname]" required={true} />
						<Input type="text" label="Last name" id="lastname" name="candidate[lastname]" required={true} />
						<Input type="email" label="Email" id="email" name="candidate[email]" required={true} />
						<Input type="tel" label="Phone" id="phone" name="candidate[phone]" required={true} />

						<div className="focus-within:text-primary text-tertiary">
							<label htmlFor="cv" className="flex flex-col">
								CV
							</label>

							<input
								type="file"
								id="cv"
								name="candidate[cv]"
								className="block mt-1 text-white focus:outline-none focus:border-primary"
							/>
							<span className="text-xs leading-none font-extralight text-tertiary">Max file size is 10MB</span>
						</div>

						<div className="lg:col-span-2 self-x-center">
							<Button type="submit" pending={pendingCandidate} disabled={pendingCandidate}>
								Send
							</Button>
						</div>
					</form>
				</div>
			</Section>
		</Layout>
	);
}

export const query = graphql`
	query Career($id: String) {
		career(id: { eq: $id }) {
			title
			slug
			description
			requirements
			city
		}
	}
`;
